import React, { useState, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'

import { states } from '../../constants'
import { getCompanyInfo, getAddressByCep } from '../../services/v3'
import { formCompanyNomination, sanitizeMoney } from '../../helpers'

import { useUser } from 'context/user-context'

import { Checkbox, Radio } from '../'
import {
  FormText,
  StyledFormLeftInput,
  StyledFormRigthInput,
  StyledNumberInput,
  StyledFormRow,
  StyledPhoneNumberInput
} from './style'

import CompanyData from './CompanyData/CompanyData'

const formDefaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  street: '',
  number: '',
  zip_code: '',
  complement: '',
  city: '',
  uf: '',
  celphone: '',
  telephone: '',
  cnpj: '',
  company_name: '',
  company_site: '',
  average_monthly_revenue: '',
  desired_amount: '',
  company_history: '',
  lending_reason: '',
  franchise_description: '',
  franchisor_name: ''
}

const optionsCompanyType = [
  {
    value: 'Franquia',
    label: 'Franquias'
  },
  {
    value: 'Geral',
    label: 'Empresas em geral'
  }
]

const FormIndication = ({ isLogged = false, onSubmit }) => {
  const { user } = useUser()

  const [isMinDesired, setIsMinDesired] = useState(false)
  const [notSend, setNotSend] = useState(false)
  const [err, setErr] = useState({})

  const { handleSubmit, errors, control, watch, setValue } = useForm({
    resolver: yupResolver(formCompanyNomination),
    defaultValues: formDefaultValues,
    mode: 'all',
    shouldUnregister: false
  })

  const values = watch(['company_type', 'franchise_type', 'have_cnpj', 'franchisor'])
  const cnpjValue = watch('cnpj')
  const zipCode = watch('zip_code')
  const desiredAmount = watch('desired_amount')

  const handleContactData = useCallback(async () => {
    try {
      if (!user || !user.partner) {
        throw Error()
      }

      if (!!user.partner.first_name) {
        setValue('first_name', user.partner.first_name)
      }

      if (!!user.partner.last_name) {
        setValue('last_name', user.partner.last_name)
      }

      if (!!user.partner.celphone && !!user.partner.celphone_prefix) {
        const celphone = user.partner.celphone_prefix + user.partner.celphone
        setValue('celphone', celphone)
      }

      if (!!user.email) {
        setValue('email', user.email)
      }

      if (!!user.partner.telephone && user.partner.telephone_prefix) {
        const telephone = user.partner.telephone_prefix + user.partner.telephone
        setValue('telephone', telephone)
      }
    } catch (error) {
      toast.error('Não foi possível obter as informações do usuário.')
    }
  }, [setValue, user])

  const handleCompanyName = useCallback(
    async cnpj => {
      try {
        const companyInfo = await getCompanyInfo(cnpj)

        if (companyInfo.status === 'ERROR') {
          throw new Error(companyInfo.message)
        }

        setValue('company_name', companyInfo.nome)
      } catch (error) {
        if (error.message === 'Erro ao buscar o CNPJ: undefined.') {
          toast.error('Erro ao buscar CNPJ')

          return
        }

        toast.error(error.message)
      }
    },
    [setValue]
  )

  const handleCompanyAddress = useCallback(
    async cep => {
      try {
        const companyAddress = await getAddressByCep(cep)

        setValue('city', companyAddress.localidade)
        setValue('uf', companyAddress.uf)
        setValue('street', companyAddress.logradouro)
      } catch (error) {
        toast.error(error.message || 'Erro a buscar CEP')
      }
    },
    [setValue]
  )

  const changeCheckbox = useCallback(
    event => {
      if (event.target.checked) {
        handleContactData()
        return
      }
      setValue('first_name', '')
      setValue('last_name', '')
      setValue('celphone', '')
      setValue('email', '')
      setValue('telephone', '')
    },
    [handleContactData, setValue]
  )

  useEffect(() => {
    if (sanitizeMoney(desiredAmount) !== 0) {
      if (sanitizeMoney(desiredAmount) < 50000) {
        setNotSend(true)
        setErr({ type: 'erro', message: 'Só são permitidas solicitações a partir de R$ 50.000,00' })
      } else if (sanitizeMoney(desiredAmount) > 500000) {
        setNotSend(true)

        setErr({ type: 'erro', message: 'Limite máximo para solicitações de R$ 500.000,00' })
      } else {
        setErr({})
        setNotSend(false)
      }
    } else {
      setErr({})
    }
  }, [desiredAmount])

  useEffect(() => {
    if (
      values.company_type === 'Geral' &&
      (values.franchise_type === 'Nova' || values.franchise_type === 'Existente')
    ) {
      setValue('have_cnpj', undefined)
      setValue('franchise_type', undefined)
      setValue('franchisor', undefined)
      setValue('franchisor', undefined)
    }
  }, [values])

  useEffect(() => {
    if (!cnpjValue) {
      return
    }

    const sanitizedCnpj = cnpjValue.replace(/\D/g, '')

    if (sanitizedCnpj.length === 14) {
      handleCompanyName(sanitizedCnpj)
    }
  }, [cnpjValue, handleCompanyName])

  useEffect(() => {
    if (!zipCode) {
      return
    }

    const sanitizedZipCode = zipCode.replace(/\D/g, '')

    if (sanitizedZipCode.length === 8) {
      handleCompanyAddress(sanitizedZipCode)
    }
  }, [zipCode, handleCompanyAddress])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Verifique o preenchimento do formulario!')
    }
  }, [errors])

  return (
    <div>
      <FormText>Dados de contato</FormText>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: '40px' }}>
        {isLogged && (
          <StyledFormRow>
            <Checkbox
              onChange={e => changeCheckbox(e)}
              name='mock_checkbox'
              control={control}
              label='Eu serei o contato responsável pelo envio dos documentos e informações financeiras'
              error={errors.mock_checkbox}
            />
          </StyledFormRow>
        )}
        <StyledFormRow>
          <StyledFormLeftInput error={errors.first_name} label='Nome *' control={control} name='first_name' />
          <StyledFormRigthInput
            mt={5}
            error={errors.last_name}
            label='Sobrenome *'
            control={control}
            name='last_name'
            mb='0px !important'
          />
        </StyledFormRow>
        <StyledFormRow>
          <StyledNumberInput mt={5} error={errors.celphone} inputType='celphone' control={control} name='celphone' />
          <StyledPhoneNumberInput mt={5} inputType='phone' control={control} name='telephone' mb='0px !important' />
        </StyledFormRow>
        <StyledFormRow>
          <StyledFormLeftInput
            mt={5}
            error={errors.email}
            label='E-mail *'
            control={control}
            name='email'
            mb='0px !important'
          />
        </StyledFormRow>

        <FormText mt='40px'>Qual tipo de empresa você está indicando</FormText>
        <StyledFormRow>
          <Radio name='company_type' control={control} error={errors.company_type} options={optionsCompanyType} />
        </StyledFormRow>
        <CompanyData
          control={control}
          errors={errors}
          states={states}
          values={values}
          isMinDesired={isMinDesired}
          notSend={notSend}
          err={err}
        />
      </form>
    </div>
  )
}

export default FormIndication
