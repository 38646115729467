export const publicMenus = [
  { label: 'Home', link: '/' },
  { label: 'Sobre nós', link: '/sobre-nos' },
  { label: 'Parcerias', link: '/parcerias' },
  { label: 'Blog', link: 'https://blog.kavodlending.com/', variant: 'external' },
  { label: 'Quero investir', link: '/login' },
  { label: 'Quero empréstimo', link: '/solicitar-credito' },
  { label: 'Entrar', link: '/login' }
]

export const defaultPrivateMenus = [
  { label: 'Parcerias', link: '/parcerias' },
  { label: 'Blog', link: 'https://blog.kavodlending.com/', variant: 'external' },
  { label: 'Campanhas', link: '/campanhas' }
]

export const privateMenuPerRole = {
  Signer: [{ label: 'Assinaturas', link: '/assinaturas' }],
  Administrator: [{ label: 'Admin', link: '/admin' }]
}

export const userOptionsPerRole = {
  Partner: [{ label: 'Minhas indicações', link: '/minhas-indicacoes' }]
}

export const termsOfUseOptions = [
  {
    label: 'Termos e condições de uso da plataforma',
    link: 'https://s3-sa-east-1.amazonaws.com/esh-documents-hmg/terms/Kavod-Lending-Termos-e-Condic%CC%A7o%CC%83es-de-Uso-de-Plataforma-Digital.pdf',
    variant: 'external'
  },
  {
    label: 'Termos e condições de uso do investidor',
    link: 'https://s3-sa-east-1.amazonaws.com/esh-documents-hmg/terms/Kavod-Lending-+Termos+e+Condic%CC%A7o%CC%83es+para+Investidores+e+Tomadores_Maio+2019+%5BFINAL%5D.pdf',
    variant: 'external'
  }
]

export const contactOptions = [
  { label: 'Fale conosco', link: '/fale-conosco' },
  { label: 'Trabalhe conosco', link: '/trabalhe-conosco' },
  {
    label: 'Suporte via Whatsapp',
    link: 'https://api.whatsapp.com/send?phone=5511975052863&text=Oi%20KavodLending%21%20Voc%C3%AA%20pode%20me%20ajudar%3F',
    variant: 'external'
  }
]

export const defaultUserOptions = [
  { label: 'Meus dados', link: '/meus-dados' },
  { label: 'Meus investimentos', link: '/meus-investimentos' },
  { label: 'Extrato', link: '/extrato' },
  {
    label: 'Avalie a Kavod',
    link: 'https://yubb.com.br/melhores-empresas-de-investimento/kavod-lending',
    variant: 'external'
  }
]

export const registrationOptions = [
  { label: 'Empresa', link: '/solicitar-credito' },
  { label: 'Investidor', link: '/cadastrar' },
  { label: 'Parceiro', link: '/cadastro-de-parceiro' }
]
