export const MEDIADESKTOP = 1200
export const MEDIATABLET = 768

export const PRIVATE_ROUTES = ['/', '/dashboard', '/admin']

export const PUBLIC_ROUTES = [
  '/login',
  '/passwordrecovery',
  '/feedback-recuperacao-senha',
  '/cadastrar',
  '/feedback-cadastro',
  '/fale-conosco',
  '/agradecemos-contato'
]

export const REPORT_INCOME_ACTIVATED = true

export const pledgeStatuses = {
  ACCEPTED: 'AC',
  ACTIVE: 'A',
  CANCELLED: 'C',
  DELETED: 'D',
  ON_HOLD: 'OH'
}
