import React from 'react'

import { Checkbox, Input, NumberInput, Radio, Button, Text } from '../../'

import { FormText } from '../style'

import {
  StyledNumberInput,
  StyledComanyNameInput,
  StyledInput,
  StyledMedianInput,
  StyledNumberStretInput,
  StyledComplementInput,
  StyledAvarageNumberInput,
  StyledFormRow,
  StyledCompanySiteNameInput,
  StyledSelect,
  ButtonDiv
} from './style'

const optionsFranciseType = [
  {
    value: 'Existente',
    label: 'Unidade em operação'
  },
  {
    value: 'Nova',
    label: 'Nova loja'
  }
]

const CompanyData = ({ errors, control, states, values, notSend, err }) => {
  return (
    <>
      {values.company_type === 'Franquia' && (
        <>
          <StyledFormRow>
            <Checkbox name='franchisor' control={control} label='Sou o franqueador' mt='32px !important' />
          </StyledFormRow>
          <FormText mt='40px' mb='40px !important'>
            Dados da empresa
          </FormText>
          <StyledFormRow>
            <Radio name='franchise_type' control={control} options={optionsFranciseType} />
          </StyledFormRow>
          <StyledFormRow>
            {!values.franchisor && (
              <StyledMedianInput
                label='Franqueador *'
                control={control}
                error={errors.franchisor_name}
                name='franchisor_name'
                mt={5}
              />
            )}
          </StyledFormRow>
          <StyledFormRow>
            <Input
              mt={5}
              mb='0px !important'
              error={errors.franchise_description}
              label='Descrição da franquia *'
              control={control}
              name='franchise_description'
              width='100%'
              multiline
              rows={5}
            />
          </StyledFormRow>
          {values.franchise_type === 'Nova' && (
            <>
              <StyledFormRow>
                <Checkbox name='have_cnpj' control={control} label='Já possuo CNPJ' mt='32px !important' />
              </StyledFormRow>
              {values.have_cnpj && (
                <StyledFormRow>
                  <Checkbox
                    name='have_pj_account'
                    control={control}
                    label='Já possuo conta PJ aberta'
                    mt='24px !important'
                  />
                </StyledFormRow>
              )}
            </>
          )}
        </>
      )}

      {(values.company_type === 'Geral' || values.franchise_type === 'Existente' || values.have_cnpj) && (
        <>
          <FormText mt='40px' mb='0px !important'>
            Dados da empresa
          </FormText>
          <StyledFormRow>
            <StyledNumberInput mt={5} inputType='cnpj' control={control} name='cnpj' error={errors.cnpj} />

            <StyledComanyNameInput
              mt={5}
              label='Empresa (Razão social) *'
              error={errors.company_name}
              control={control}
              name='company_name'
            />
          </StyledFormRow>
          <StyledFormRow>
            <StyledCompanySiteNameInput
              label='Site da empresa (opcional)'
              control={control}
              name='company_site'
              error={errors.company_site}
              mt={5}
              mb='0px !important'
            />
          </StyledFormRow>
          <StyledFormRow>
            <StyledNumberInput mt={5} inputType='cep' control={control} name='zip_code' error={errors.zip_code} />

            <StyledInput mt={5} label='Rua *' control={control} name='street' error={errors.street} />

            <StyledNumberStretInput mt={5} label='Nº *' control={control} name='number' error={errors.number} />
          </StyledFormRow>
          <StyledFormRow>
            <StyledComplementInput mt={5} label='Complemento' control={control} name='complement' />
            <StyledInput mt={5} label='Cidade *' control={control} name='city' error={errors.city} />
            <StyledSelect
              mt={5}
              label='Estado *'
              control={control}
              name='uf'
              error={errors.uf}
              options={states.map(({ id, text }) => ({ value: id, label: text }))}
            />
          </StyledFormRow>
          <StyledFormRow>
            <StyledAvarageNumberInput
              mt={5}
              error={errors.average_monthly_revenue}
              inputType='currency'
              label='Faturamento médio mensal estimado *   '
              name='average_monthly_revenue'
              control={control}
            />
            <NumberInput
              mt={5}
              mb='0px !important'
              error={Object.keys(err).length !== 0 ? err : errors.desired_amount}
              inputType='currency'
              label='Valor do empréstimo desejado *'
              name='desired_amount'
              control={control}
              width='100%'
            />
          </StyledFormRow>

          {values.company_type === 'Geral' && (
            <StyledFormRow>
              <Input
                mt={5}
                mb='0px !important'
                error={errors.company_history}
                label='Histórico da empresa *'
                control={control}
                name='company_history'
                width='100%'
                multiline
                rows={5}
              />
            </StyledFormRow>
          )}
          <StyledFormRow>
            <Input
              mt={5}
              mb='0px !important'
              error={errors.lending_reason}
              label='Motivo do empréstimo *'
              control={control}
              name='lending_reason'
              width='100%'
              multiline
              rows={5}
            />
          </StyledFormRow>
          <ButtonDiv>
            <Button mt={5} type='submit' width='384px' disabled={notSend}>
              Enviar solicitação
            </Button>
          </ButtonDiv>
        </>
      )}

      {values.franchise_type === 'Nova' && !values.have_cnpj && (
        <>
          <FormText mt='40px' mb='0px !important'>
            Dados da empresa
          </FormText>
          <StyledFormRow>
            <StyledComanyNameInput
              mt={5}
              label='Empresa (Razão social) *'
              error={errors.company_name}
              control={control}
              name='company_name'
            />
          </StyledFormRow>
          <StyledFormRow>
            <StyledAvarageNumberInput
              mt={5}
              error={errors.average_monthly_revenue}
              inputType='currency'
              label='Faturamento médio mensal estimado *'
              name='average_monthly_revenue'
              control={control}
            />
            <NumberInput
              mt={5}
              mb='0px !important'
              error={Object.keys(err).length !== 0 ? err : errors.desired_amount}
              inputType='currency'
              label='Valor do empréstimo desejado *'
              name='desired_amount'
              control={control}
              width='100%'
            />
          </StyledFormRow>

          <StyledFormRow>
            <Input
              mt={5}
              mb='0px !important'
              error={errors.lending_reason}
              label='Motivo do empréstimo *'
              control={control}
              name='lending_reason'
              width='100%'
              multiline
              rows={5}
            />
          </StyledFormRow>
          <ButtonDiv>
            <Button mt={5} type='submit' width='384px' disabled={notSend}>
              Enviar solicitação
            </Button>
          </ButtonDiv>
        </>
      )}
    </>
  )
}

export default CompanyData
